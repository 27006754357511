body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-autosuggest__container {
    border: none;
}

.react-autosuggest__input {
    border: none;
    background: transparent;
    color: #fff;

    width: 100%;
    -webkit-transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding-top: 8px;
    padding-left: 80px;
    padding-right: 8px;
    padding-bottom: 8px;
}
.react-autosuggest__input:focus,
.react-autosuggest__input:active {
    border: none;
}

.react-autosuggest__suggestions-container--open {
    position: absolute;
    background: white;
    border: 1px solid #aaa;
    width: 100%;
}
.react-autosuggest__suggestion--highlighted {
    background: #ccc;
}
